import { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { doc, getDoc, updateDoc, setDoc, collection, onSnapshot } from "firebase/firestore"

import { LoadingService } from "../../react-ui-components/components/LoadingService"
import { auth, db } from "../../firebase"
import { PopupService } from "../../react-ui-components/components/PopupService"
import Raffles from "../../react-ui-components/components/Raffles"
import { useLocation } from "../../react-ui-components/components/location"
import { Login } from "../LoginScreen/Login"
import { useRaffleContext } from "../../contexts/RaffleContext"

const listenForRaffleConfigChanges = function (setData: any) {
  LoadingService.setLoading(true)
  const querySnapshot = doc(db, "app-configurations/display-content-config")

  return onSnapshot(querySnapshot, {
    next: (data) => {
      setData(data.data())
      LoadingService.setLoading(false)
    },
    error: (data) => {
      LoadingService.setLoading(true, data?.message || data, true)
    },
    complete: () => {
      LoadingService.setLoading(false)
    },
  })
}

export default function RafflesComponent() {
  const [user] = useAuthState(auth)
  const { raffles, setRaffles, fetchRaffles } = useRaffleContext()
  const { currentLocation: location } = useLocation()

  const [rafflesConfig, setRafflesConfig] = useState({
    descriptionLength: 50,
    hideAllActions: true,
    imageResizeMode: "contain",
    rafflesImageHeight: 500,
  })

  useEffect(() => {
    return listenForRaffleConfigChanges(setRafflesConfig)
  }, [location, user])

  useEffect(() => {
    if (location) {
      fetchRaffles(location)
    }
  }, [location, user])

  const handleLike = async (raffleId: string): Promise<any> => {
    if (!user || user.isAnonymous) {
      return PopupService.setPopup(<Login />)
    }

    const likeRef = doc(db, `raffles/${raffleId}/likes`, user.uid)
    const likeDoc = await getDoc(likeRef)

    if (likeDoc.exists()) {
      await updateDoc(likeRef, { liked: !likeDoc.data().liked })
    } else {
      await setDoc(likeRef, { liked: true })
    }

    setRaffles(
      raffles.map((raffle: any) =>
        raffle.id === raffleId ? { ...raffle, liked: !raffle.liked } : raffle,
      ),
    )
  }

  return (
    <Raffles
      raffles={raffles}
      user={user}
      rafflesConfig={rafflesConfig}
      handleLike={handleLike}
      getLikesRef={(raffleId: string) => collection(db, `raffles/${raffleId}/likes`)}
      fetchLikes={null}
      termsIconProps={{
        src: require("../../assets/icons/termsIcon.png"),
        height: 32,
      }}
    />
  )
}
