import { useEffect, useState } from 'react';

import { signInAnonymously, signInWithEmailAndPassword } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useUserStore } from './components/stores/userStore';
import { auth } from './firebase';
import i18n from './i18n';
import { LoadingService } from './react-ui-components/components/LoadingService';

async function loginUserAnonymously(user: any) {
  try {
    if (!user) {
      LoadingService.setLoading(true);
      signInAnonymously(auth);
    }
  } catch (error: any) {
    LoadingService.setLoading(true, error?.message || error, true);
  } finally {
    LoadingService.setLoading(false);
  }
}

async function login(email: string, password: string) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    console.log('User logged in:', userCredential.user);
  } catch (error) {
    console.error('Error logging in:', error);
  }
}

export const AuthProvider = (props: any) => {
  const { children, partial } = props;
  const [user, loading, error] = useAuthState(auth);
  const [canAccess, setCanAccess] = useState<boolean>(false);
  const { loadUser } = useUserStore();

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      try {
        LoadingService.show();
        await i18n.init();
        setIsInitialized(true);
      } catch (err: any) {
        console.log(err);
      } finally {
        LoadingService.hide();
      }
    };

    initialize();
  }, []);

  useEffect(() => {
    setCanAccess(false);

    if (loading) {
      return;
    }

    if (!user) {
      loginUserAnonymously(user);
    } else {
      setCanAccess(true);
      if (!partial) {
        setCanAccess(!user.isAnonymous);
      }     
    }
  }, [partial, user, loading]);

  if (!canAccess) {
    return null;
  }

  return !isInitialized ? null : children;
};
