import React from "react"
import { Logo } from "./components/Logo"
import { UserLocation } from "./components/UserLocation"

import Toolbar from "@mui/material/Toolbar"
import { UserPoints } from "./components/UserPoints"
import { UsersTickets } from "./components/UserTickets"
import { Login } from "./components/LoginScreen/Login"
import { Button, Typography } from "@mui/material"
import { PopupService } from "./react-ui-components/components/PopupService"
import { useTranslation } from "react-i18next"
import { useUserStore } from "./components/stores/userStore"

export const TopToolbar: React.FC = () => {
  const { t } = useTranslation()
  const { isLoggedIn } = useUserStore()
  return (
    <Toolbar>
      <div className="flex gap-1 justify-start w-full">
        <Logo width={100} cropped />
      </div>
      <div className="flex flex-col w-full gap-1 items-end">
        <UserLocation fullWidth textButton viewOnly color="secondary" />
        <div className="flex flex-row gap-1">
          <UsersTickets />
          <UserPoints />
          {isLoggedIn ? null : (
            <Button
              variant="text"
              className="text-white"
              onClick={() => PopupService.pushPopup(<Login />)}
            >
              <Typography>{t("Login")}</Typography>
            </Button>
          )}
        </div>
      </div>
    </Toolbar>
  )
}
