import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "react-lazy-load-image-component/src/effects/blur.css"
import "./App.css"
import "./index.css"
import "./router.css"

import React from "react"

import ReactDOM from "react-dom/client"

import { CssBaseline } from "@mui/material"

import { AuthProvider } from "./AuthProvider"
import { LocationProvider } from "./components/LocationService"
import { Provider } from "./MuiProvider"
import { LoadingProvider } from "./react-ui-components/components/LoadingService"
import { PopupProvider } from "./react-ui-components/components/PopupService"
import reportWebVitals from "./reportWebVitals"
import { Routes } from "./routes"
import { SidebarProvider } from "./SidebarProvider"

import { BrowserRouter, Routes as RoutesBase } from "react-router-dom"

import ScrollToAnchor from "./ScrollToAnchor"
import { AppProvider } from "./components/stores/UserContext"
import { RaffleProvider } from "./contexts/RaffleContext"

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope)
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error)
    })
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <BrowserRouter>
    <SidebarProvider>
      <LoadingProvider>
        <AppProvider>
          <LocationProvider>
            <AuthProvider partial>
              <RaffleProvider>
                <PopupProvider>
                  <div id="__t">
                    <Provider>
                      <React.StrictMode>
                        <CssBaseline />
                        <Routes />
                      </React.StrictMode>
                    </Provider>
                  </div>
                </PopupProvider>
              </RaffleProvider>
            </AuthProvider>
          </LocationProvider>
        </AppProvider>
      </LoadingProvider>
    </SidebarProvider>

    <ScrollToAnchor />
  </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
