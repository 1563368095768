import { DateTime } from "luxon"

export const getLuxonDate = (date: any, isSeconds = false): any => {
  if (!date) {
    return console.log(`Invalid date (${date}).`)
  }
  let date_ = null
  if (isSeconds) {
    date_ = DateTime.fromMillis(date)
    date_ = date_.isValid ? date_ : DateTime.fromSeconds(date)
  } else {
    date_ = DateTime.fromISO(date)
    date_ = date_.isValid ? date_ : DateTime.fromJSDate(date)
  }
  return date_
}

export const getCreatedDate = (): number => {
  return +DateTime.now().setZone("utc").toFormat("x")
}

export const getDate = (plus: {
  days?: number
  minutes?: number
  hours?: number
  months?: number
}): number => {
  return +DateTime.now().plus(plus).setZone("utc").toFormat("x")
}

export const getMilliDate = (date: any): number => {
  if (Number.isInteger(date)) {
    return date
  }
  return +getLuxonDate(date).toFormat("x")
}

export const format = (date: any, format: string, isSeconds = false) => {
  const date_ = getLuxonDate(date, isSeconds)
  return date_.toLocaleString(format)
}
