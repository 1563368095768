import React, { useState } from "react"
import {
  getAuth,
  updatePhoneNumber,
  PhoneAuthProvider,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  User,
} from "firebase/auth"
import { MuiPhone } from "../MuiPhone"
import { ParsedCountry } from "react-international-phone"
import { IUser } from "../stores/IUser"
import { PopupService } from "../../react-ui-components/components/PopupService"
import { Button, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"

// Define interface for props (if needed)
interface UpdatePhoneNumberProps {
  userInfo: IUser | null
}

const UpdatePhoneNumber: React.FC<UpdatePhoneNumberProps> = ({ userInfo }) => {
  const [phoneNumber, setPhoneNumber] = useState(userInfo?.phoneNumber || "")
  const [verificationCode, setVerificationCode] = useState("")
  const [loading, setLoading] = useState(false)
  const [confirmationResult, setConfirmationResult] = useState<any>(null)
  const [error, setError] = useState<string | null>(null)
  const auth = getAuth()
  const { t } = useTranslation()

  /**
   * Function to handle phone number update by sending OTP.
   */
  const handleSendOTP = async () => {
    setError(null)

    if (!/^\+?[1-9]\d{1,14}$/.test(phoneNumber)) {
      setError("Invalid phone number. Must be in international format.")
      return
    }

    setLoading(true)

    try {
      // Initialize reCAPTCHA verifier
      const recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
        callback: (response: any) => {
          console.log("reCAPTCHA solved:", response)
        },
      })

      // Send OTP
      const confirmation = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
      setConfirmationResult(confirmation)
    } catch (error: any) {
      setError("There was an error sending verification code.")
    } finally {
      setLoading(false)
    }
  }

  /**
   * Function to verify OTP and update phone number.
   */
  const handleVerifyOTP = async () => {
    setError(null)

    if (!confirmationResult) {
      PopupService.pushPopup(t("Please Enter A Verification Code First"))
      return
    }

    if (!/^\d{6}$/.test(verificationCode)) {
      setError(t("There was an error sending verification code."))
      return
    }

    setLoading(true)

    try {
      const credential = PhoneAuthProvider.credential(
        confirmationResult.verificationId,
        verificationCode,
      )
      await updatePhoneNumber(auth.currentUser!, credential)
      PopupService.pushPopup("Phone number updated successfully!")
      setConfirmationResult(null)
    } catch (error: any) {
      setError(t("There was an error sending verification code."))
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="phone-update-container flex flex-col gap-2">
      <MuiPhone
        disabled={loading}
        value={phoneNumber}
        onChange={function (phone: string, inputValue: string, country: ParsedCountry): void {
          setPhoneNumber(phone)
        }}
      />
      {/* <button onClick={handleSendOTP} disabled={loading}>
        {loading ? "Sending OTP..." : "Send OTP"}
      </button> */}
      {confirmationResult ? null : (
        <Button variant="contained" color="primary" onClick={handleSendOTP}>
          {loading ? t("loading") : t("Send Verification Code")}
        </Button>
      )}

      {/* OTP Verification */}
      {confirmationResult && (
        <>
          <TextField
            margin="normal"
            fullWidth
            label={t("Verification code")}
            name="verificationCode"
            value={verificationCode}
            onChange={({ target }) => setVerificationCode(target.value)}
            slotProps={{
              input: {
                endAdornment: (
                  <Button variant="contained" color="primary" onClick={handleVerifyOTP}>
                    {t("send")}
                  </Button>
                ),
              },
            }}
          />
          {/* <input
            type="text"
            placeholder="Enter verification code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            disabled={loading}
          />
          <button onClick={handleVerifyOTP} disabled={loading}>
            {loading ? "Verifying..." : "Verify & Update"}
          </button> */}
        </>
      )}

      {/* Error Message */}
      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* Invisible reCAPTCHA */}
      <div id="recaptcha-container"></div>
    </div>
  )
}

export default UpdatePhoneNumber
