import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material"
import React from "react"
import Cropper from "react-easy-crop"
import CountrySelector from "../../CountrySelector"
import LanguageSelector from "../../LanguageSelector"
import { IUser } from "../stores/IUser"

import { useTranslation } from "react-i18next"
import { useUserStore } from "../stores/userStore"
import { MuiPhone } from "../MuiPhone"

export function ProfileInfo(props: {
  imagePreview: string | undefined
  isSmallScreen: boolean
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  updatedProfile: IUser | null | undefined
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onCancel: () => void
  handleSave: () => Promise<void>
  cropDialogOpen: boolean
  handleCropDialogClose: () => void
  crop: { x: number; y: number }
  zoom: number
  setCrop: React.Dispatch<React.SetStateAction<{ x: number; y: number }>>
  setZoom: React.Dispatch<React.SetStateAction<number>>
  onCropComplete: (croppedArea: any, croppedAreaPixels: any) => void
  handleCropDialogSave: () => Promise<void>
}) {
  const {
    imagePreview,
    isSmallScreen,
    handleImageChange,
    updatedProfile,
    handleChange,
    onCancel,
    handleSave,
    cropDialogOpen,
    handleCropDialogClose,
    crop,
    zoom,
    setCrop,
    setZoom,
    onCropComplete,
    handleCropDialogSave,
  } = props

  const { t } = useTranslation()
  const { isPhoneProvider, isEmailProvider } = useUserStore()

  return (
    <Card sx={{ maxWidth: 345, mx: "auto", mt: 4 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {t("profile.editProfile")}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <Avatar
            variant="circular"
            src={imagePreview}
            style={{
              width: isSmallScreen ? 100 : 140,
              height: isSmallScreen ? 100 : 140,
              borderRadius: "50%",
            }}
          />
        </Box>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
          id="profile-image-upload"
        />
        <label htmlFor="profile-image-upload">
          <Button variant="outlined" color="primary" component="span">
            {t("profile.uploadNewImage")}
          </Button>
        </label>
        <TextField
          margin="normal"
          fullWidth
          label={t("profile.name")}
          name="name"
          value={updatedProfile?.name}
          onChange={handleChange}
        />
        <TextField
          disabled={isEmailProvider}
          margin="normal"
          fullWidth
          label={t("profile.email")}
          name="email"
          value={updatedProfile?.email}
          onChange={handleChange}
          helperText={isEmailProvider ? t("CantUpdateEmail") : ""}
        />
        <MuiPhone
          disabled={isPhoneProvider}
          margin="normal"
          fullWidth
          label={t("profile.phoneNumber")}
          name="phoneNumber"
          value={updatedProfile?.phoneNumber || ""}
          onChange={(phone) =>
            handleChange({
              target: {
                value: phone,
                name: "phoneNumber",
              },
            } as any)
          }
          helperText={isPhoneProvider ? t("CantUpdatePhone") : ""}
        />
        {/* <TextField
          disabled={isPhoneProvider}
          margin="normal"
          fullWidth
          label={t("profile.phoneNumber")}
          name="phoneNumber"
          value={updatedProfile?.phoneNumber}
          onChange={handleChange}
        /> */}
        <CountrySelector currentCountry={updatedProfile?.country} onChange={handleChange} useCode />
        <LanguageSelector onChange={handleChange} useValue value={updatedProfile?.language||''} />
      </CardContent>
      <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          {t("profile.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          {t("profile.save")}
        </Button>
      </Box>

      <Dialog open={cropDialogOpen} onClose={handleCropDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>{t("profile.cropImage")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: 400,
              backgroundColor: "#333",
            }}
          >
            <Cropper
              image={imagePreview}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCropDialogClose}>{t("profile.cancel")}</Button>
          <Button onClick={handleCropDialogSave} variant="contained" color="primary">
            {t("profile.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}
