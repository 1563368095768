import React, { createContext, useContext, useState, useEffect } from "react"
import { onSnapshot, query, where, collection } from "firebase/firestore"
import { db } from "../firebase"
import { ILocationItem } from "../react-ui-components/components/models/ILocation"
import { LoadingService } from "../react-ui-components/components/LoadingService"
import { IRaffle } from "../react-ui-components/components/models"
import { useLocation } from "../react-ui-components/components/location"
import { useUserStore } from "../components/stores/userStore"

interface RaffleContextProps {
  raffles: any[]
  setRaffles: React.Dispatch<React.SetStateAction<any[]>>
  fetchRaffles: (location: ILocationItem) => void
}

const RaffleContext = createContext<RaffleContextProps | undefined>(undefined)

export const RaffleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [raffles, setRaffles] = useState<IRaffle[]>([])
  const { currentLocation } = useLocation()
  const { isLoggedIn } = useUserStore()

  const fetchRaffles = (location: ILocationItem) => {
    LoadingService.setLoading(true)

    const conditions: any[] = [where("raffleDate", ">=", Date.now())]

    const country = location.country?.trim()
    if (country && country !== "*" && country !== "") {
      conditions.push(where("location.country", "in", [country, "", "*"]))
    }

    const state = location.state?.trim()
    if (state && state !== "*" && state !== "") {
      conditions.push(where("location.province", "in", [state, "", "*"]))
    }

    const city = location.city?.trim()
    if (city && city !== "*" && city !== "") {
      conditions.push(where("location.city", "in", [city, "", "*"]))
    }

    const raffleQuery = query(collection(db, "raffles"), ...conditions)

    return onSnapshot(
      raffleQuery,
      (snap) => {
        const docs: any[] = []
        snap.forEach((doc) => docs.push(doc.data()))
        setRaffles(docs)
        LoadingService.setLoading(false)
      },
      (error) => {
        console.error(error)
        setRaffles([])
        LoadingService.setLoading(false)
      },
    )
  }

  useEffect(() => {
    if (isLoggedIn) {
      return fetchRaffles(currentLocation)
    }
  }, [isLoggedIn])

  return (
    <RaffleContext.Provider value={{ raffles, setRaffles, fetchRaffles }}>
      {children}
    </RaffleContext.Provider>
  )
}

export const useRaffleContext = () => {
  const context = useContext(RaffleContext)
  if (!context) {
    throw new Error("useRaffleContext must be used within a RaffleProvider")
  }
  return context
}
