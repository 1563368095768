import React, { useRef, useState } from "react"
import { httpsCallable } from "firebase/functions"
import Camera from "@mui/icons-material/CameraAltTwoTone"
import CameraTwoToneIcon from "@mui/icons-material/CameraTwoTone"
import { Dialog, DialogContent, IconButton } from "@mui/material"

import { functions } from "../firebase"
import { PopupService } from "../react-ui-components/components/PopupService"
import { useUserStore } from "./stores/userStore"
import { Html5QrcodeScanner } from "html5-qrcode"
import { useTranslation } from "react-i18next"
import QRCamera from "./QRCamera"
import Clear from "@mui/icons-material/Clear"

const processUserPrepayTickets = httpsCallable(functions, "processUserPrepayTickets")
let scanning = false
const QRCodeScanner: React.FC = () => {
  const [isScanning, setIsScanning] = useState(false)
  const [scanResult, setScanResult] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const scannerRef = useRef<Html5QrcodeScanner | null>(null)
  const { isLoggedIn } = useUserStore()

  const { t } = useTranslation()

  // Initialize or clear the scanner
  // useEffect(() => {
  //   if (isScanning) {
  //     setTimeout(() => {
  //       const config = createConfig({ fps: 10, qrbox: { width: 250, height: 250 } })
  //       const verbose = process.env.NODE_ENV === "development"
  //       scannerRef.current = new Html5QrcodeScanner(qrcodeRegionId, config, verbose)

  //       scannerRef.current.render(handleScanSuccess, (errorMessage: any) =>
  //         console.warn(errorMessage),
  //       )
  //     }, 100) // Slight delay to ensure the DOM is ready
  //   } else if (scannerRef.current) {
  //     scannerRef.current.clear()
  //     scannerRef.current = null
  //   }

  //   return () => {
  //     if (scannerRef.current) {
  //       scannerRef.current.clear()
  //     }
  //   }
  // }, [isScanning])

  const handleScanSuccess = async (decodedText: string) => {
    if (!loading) {
      setLoading(true)

      // Stop scanner to prevent duplicate scans
      if (scannerRef.current) {
        scannerRef.current.clear()
        scannerRef.current = null
      }

      setScanResult(decodedText)
      try {
        await processUserPrepayTickets({ code: decodedText })
        PopupService.setPopup(t("You have received a ticket or coupon"))
        // alert("QR Code successfully saved to Firebase!")
      } catch (error: any) {
        PopupService.setPopup(t("This code has already been scanned"))
        // PopupService.setPopup(error.message)
        console.error("Error saving QR code:", error)
      } finally {
        setLoading(false)
        setIsScanning(false)
      }
    }
  }
  // console.log("scanning--->", scanning)
  return !isLoggedIn ? null : (
    <div>
      <IconButton
        onClick={() => {
          setIsScanning(true)
        }}
        size="large"
        color="secondary"
        className={`transition-transform duration-500 ${isScanning ? "animate-spin" : ""}`}
      >
        {isScanning ? <CameraTwoToneIcon /> : <Camera />}
      </IconButton>
      <Dialog
        title=""
        className="flex justify-center"
        open={isScanning}
        onClose={() => setIsScanning(false)}
      >
        <DialogContent>
          <div
            style={{
              zIndex: 99999,
              position: "fixed",
              width: 100,
              height: 100,
              top: 0,
              right: 0,
            }}
          >
            <IconButton
              size="large"
              aria-label="close"
              onClick={() => {
                setIsScanning(false)
              }}
              color="error"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                // color: (theme) => theme.palette.grey[500],
              }}
            >
              <Clear />
            </IconButton>
          </div>
          <QRCamera
            fullScreen={true}
            overlayMessage={t("Center Code")}
            // width={screen.width}
            // height={screen.height}
            onScan={(result: any) => {
              // console.log("scanning-before-->", scanning)
              if (!scanning) {
                handleScanSuccess(result)
              }
              scanning = true
              setTimeout(() => {
                scanning = false
                // console.log("scanning--timeout->", scanning)
              }, 1000)
            }}
            onError={(error: any) => {
              console.log(error)
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default QRCodeScanner
