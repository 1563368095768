import { Button, Typography } from "@mui/material"
import htmr from "htmr"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Carousel from "react-material-ui-carousel"
import styled from "styled-components"

import { LikeComponent } from "./LikeComponent"
import { IRaffle } from "./models/IRaffle"
import { PopupService } from "./PopupService"
import TermsIcon from "./TermsIcon"
import { getCountryInfo } from "./utils"
import { Image } from "../../components/Tickets/Image"

const UnorderedList = styled.ul`
  list-style: disc !important;
  margin: 0 16px !important;
  padding-left: 40px !important;
`

const transform: any = {
  ul: UnorderedList,
  h1: styled.h1`
    font-weight: 900 !important;
    text-align: center !important;
    font-size: xx-large !important;
  `,
  h2: styled.h2`
    font-weight: 800 !important;
    text-align: center !important;
    font-size: x-large !important;
  `,
  h3: styled.h3`
    font-weight: 700 !important;
    text-align: center !important;
    font-size: larger !important;
  `,
  h4: styled.h4`
    font-weight: 600 !important;
    text-align: center !important;
    font-size: large !important;
  `,
  h5: styled.h5`
    font-weight: 500 !important;
    text-align: center !important;
    font-size: medium !important;
  `,
  h6: styled.h6`
    font-weight: 400 !important;
    text-align: center !important;
    font-size: small !important;
  `,
}

export const RaffleItem: React.FC<{
  raffle: IRaffle
  rafflesConfig: any
  termsIconProps: any
  user: any
  handleLike: (raffleId: string) => Promise<any>
  getLikesRef: any
}> = ({ getLikesRef, raffle, handleLike, rafflesConfig, termsIconProps, user }) => {
  const { t } = useTranslation()
  const imageHeight = rafflesConfig.rafflesImageHeight || "100%"
  const [emoji, setEmoji] = useState("🌍")

  useEffect(() => {
    const country = raffle.location?.country
    const ej = country ? getCountryInfo(country)?.emoji : "🌍"
    if (ej) {
      setEmoji(ej)
    }
  }, [raffle])

  return (
    <div
      onDoubleClick={() => handleLike(raffle.id)}
      style={{
        borderWidth: raffle.borderWidth || 1,
        borderColor: raffle.borderColor || "#DDD",
        borderStyle: raffle.borderStyle || "solid",
        marginTop: raffle.marginTop || 10,
        marginBottom: raffle.marginBottom || 10,
        padding: raffle.padding || 10,
      }}
    >
      {raffle.type === "Image" ? null : (
        <div>
          {/* imageResizeMode:{rafflesConfig.imageResizeMode} */}
          {raffle.title && <Typography>{htmr(raffle.title)}</Typography>}
          {raffle.subtitle && <Typography>{htmr(raffle.subtitle)}</Typography>}
          {raffle.description && <Typography>{htmr(raffle.description)}</Typography>}
        </div>
      )}
      {!raffle.raffleImages?.length ? null : (
        <Carousel
          cycleNavigation
          fullHeightHover
          indicators
          navButtonsAlwaysVisible={!!raffle.navButtonsAlwaysVisible}
          height={raffle.height || rafflesConfig.rafflesImageHeight || 400}
          stopAutoPlayOnHover={!!raffle.stopAutoPlayOnHover}
          autoPlay={raffle.autoPlay || rafflesConfig.autoPlay || true}
          animation={raffle.animation || "slide"}
          duration={raffle.animationDuration || rafflesConfig.animationDuration || true}
          swipe
          interval={raffle.playInterval || rafflesConfig.playInterval || 4000}
        >
          {raffle.raffleImages.map((item: string, i: number) => (
            <div
              key={i}
              style={{
                // borderColor: "red",
                // borderWidth: 1,
                // borderStyle: "double",

                height: imageHeight,
              }}
              className="flex justify-center"
            >
              {raffle.rafflesImagesLink ? (
                <a href={raffle.rafflesImagesLink || ""} target="_blank">
                  <Image
                    src={item}
                    style={{
                      height: imageHeight,
                      objectFit:
                        raffle.imageResizeMode || rafflesConfig.imageResizeMode || "contain", //fill | contain | cover | none | scale-down
                    }}
                    alt={"raffle image"}
                  />
                </a>
              ) : (
                <Image
                  src={item}
                  style={{
                    height: imageHeight,
                    objectFit: raffle.imageResizeMode || rafflesConfig.imageResizeMode || "contain", //fill | contain | cover | none | scale-down
                  }}
                  alt={"raffle image"}
                />
              )}
            </div>
          ))}
        </Carousel>
      )}
      {raffle.type === "Image" ? null : (
        <div className="flex flex-row gap-5">
          <p>{emoji}</p>
          <Typography>{new Date(raffle.raffleDate).toString()}</Typography>
        </div>
      )}
      {raffle.type === "Image" ||
        (typeof raffle.hideAllActions === "undefined" ? false : raffle.hideAllActions) ? null : (
        <div className="flex row gap-5 space-between justify-between items-baseline">
          <LikeComponent
            raffle={raffle}
            user={user}
            getLikesRef={getLikesRef}
            handleLike={handleLike}
          />
          <Button
            className="p-1 h-9"
            size="small"
            startIcon={<TermsIcon {...termsIconProps} />}
            variant="contained"
            color="primary"
            onClick={() => {
              PopupService.pushPopup(
                htmr(t(raffle.termsText || "No terms available yet"), {
                  transform,
                }),
              )
            }}
          >
            {t("terms")}
          </Button>
        </div>
      )}
      {/* {process.env.NODE_ENV === 'development' ? (
          <Typography variant='h4' className='text-red'>
            Country: {raffle.location?.country}
          </Typography>
        ) : null} */}
    </div>
  )
}
