import { Button, Icon } from "@mui/material"

import { toJS } from "mobx"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../firebase"
import { PopupService } from "../react-ui-components/components/PopupService"
import { formatCash } from "../utils"
import { useUserStore } from "./stores/userStore"
import Tickets from "./Tickets"
import { Login } from "./LoginScreen/Login"
import { useTranslation } from "react-i18next"

export interface UsersCounterProps {
  style?: any
}

export const UsersTickets = function UsersCounter(props: UsersCounterProps) {
  const { isLoggedIn, ticketsCount } = useUserStore()
  const { t } = useTranslation()

  const { style } = props
  const $styles = { ...$container, ...style }

  const iconDefault: any = {
    name: "book_online",
    isFont: true,
    isFa5: true,
    style: { marginLeft: 1 },
    size: "medium",
    isUrl: false,
  }

  const [user] = useAuthState(auth)

  if (!isLoggedIn) {
    return null
  }

  return (
    <Button
      variant="contained"
      style={$styles}
      className="flex flex-row gap-1/2 p-1 w-full"
      startIcon={
        <div className="flex flex-row gap-1 items-center">
          <div className="flex flex-row items-center">
            <span className="ml-2 text-[8px] sm:text-xs"> {formatCash(ticketsCount)}</span>
            <Icon className="size-5 sm:size-6">
              {iconDefault.name}
            </Icon>
          </div>

          <span className="text-[8px] sm:text-xs">{t("tickets")}</span>
        </div>
      }
      onClick={() => {
        isLoggedIn
          ? PopupService.setPopup(<Tickets />, "", {
              maxWidth: "100%",
              width: "100%",
              height: "100%",
            })
          : PopupService.setPopup(<Login />)
      }}
    ></Button>
  )
}

const $container: any = {
  justifyContent: "center",
  flexDirection: "row",
}
