import { Tab, Tabs, useMediaQuery, useTheme } from "@mui/material"
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import React, { useCallback, useState } from "react"
import { auth, storage } from "../../firebase"

import { AccountInfo } from "./AccountInfo"

import { useTranslation } from "react-i18next"
import SwipeableViews from "react-swipeable-views"
import { v4 as uuidv4 } from "uuid"
import { getCroppedImg } from "../../utils/cropImage"
import { useUserStore } from "../stores/userStore"
import { ProfileInfo } from "./ProfileInfo"
import { TabPanel } from "./TabPanel"
import { a11yProps } from "./a11yProps"
import { PopupService } from "../../react-ui-components/components/PopupService"

interface EditProfileProps {
  onCancel: () => void
  onSave: (profile: any) => void
}

const EditProfile: React.FC<EditProfileProps> = ({ onCancel, onSave }) => {
  const { t } = useTranslation()
  const { userInfo: user, updateUser } = useUserStore()
  // const { user, deleteUser, userInfo } = useUserStore()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const [updatedProfile, setUpdatedProfile] = useState(user)
  const [newImage, setNewImage] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState(user?.profileImageUrl)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null)
  const [cropDialogOpen, setCropDialogOpen] = useState(false)
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setUpdatedProfile((prevState: any) => ({ ...prevState, [name]: value }))
  }

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue)
  }

  const handleChangeIndex = (index: number) => {
    setTabValue(index)
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setNewImage(e.target.files[0])
      setImagePreview(URL.createObjectURL(e.target.files[0]))
      setCropDialogOpen(true)
    }
  }

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleSave = async () => {
    if (newImage && croppedAreaPixels) {
      try {
        if (!auth.currentUser) {
          throw t`No user logged in`
        }
        const croppedImage = await getCroppedImg(imagePreview || "", croppedAreaPixels, zoom)
        const response = await fetch(croppedImage as string)
        const blob = await response.blob()
        const uniqueId = uuidv4()
        const file = new File([blob], `${uniqueId}-${newImage.name}`, {
          type: "image/jpeg",
        })

        const storageRef = ref(storage, `users/${auth.currentUser.uid}profileImages/${file.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on(
          "state_changed",
          () => {},
          (error) => {
            console.error(error)
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
            await updateUser({
              ...updatedProfile,
              profileImageUrl: downloadURL,
            })
          },
        )
      } catch (error) {
        console.error("Error cropping image:", error)
      }
    } else {
      await updateUser(updatedProfile)
    }
    onSave && onSave(updatedProfile)
    PopupService.pop()
  }

  const handleCropDialogClose = () => {
    setCropDialogOpen(false)
  }

  const handleCropDialogSave = async () => {
    if (croppedAreaPixels) {
      try {
        const croppedImage = await getCroppedImg(imagePreview || "", croppedAreaPixels, zoom)
        setImagePreview(croppedImage as string)
        setCropDialogOpen(false)
      } catch (error) {
        console.error("Error saving cropped image:", error)
      }
    } else {
      console.error("Cropped area pixels not set")
    }
  }

  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="Profile" {...a11yProps(0)} />
        <Tab label="Account" {...a11yProps(1)} />
        {/* <Tab label="Business Offers" {...a11yProps(2)} /> */}
      </Tabs>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={tabValue}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={tabValue} index={0} dir={theme.direction}>
          <ProfileInfo
            imagePreview={imagePreview}
            isSmallScreen={isSmallScreen}
            handleImageChange={handleImageChange}
            updatedProfile={updatedProfile}
            handleChange={handleChange}
            onCancel={onCancel}
            handleSave={handleSave}
            cropDialogOpen={cropDialogOpen}
            handleCropDialogClose={handleCropDialogClose}
            crop={crop}
            zoom={zoom}
            setCrop={setCrop}
            setZoom={setZoom}
            onCropComplete={onCropComplete}
            handleCropDialogSave={handleCropDialogSave}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1} dir={theme.direction}>
          <AccountInfo
            imagePreview={imagePreview}
            isSmallScreen={isSmallScreen}
            handleImageChange={handleImageChange}
            updatedProfile={updatedProfile}
            handleChange={handleChange}
            onCancel={onCancel}
            handleSave={handleSave}
            cropDialogOpen={cropDialogOpen}
            handleCropDialogClose={handleCropDialogClose}
            crop={crop}
            zoom={zoom}
            setCrop={setCrop}
            setZoom={setZoom}
            onCropComplete={onCropComplete}
            handleCropDialogSave={handleCropDialogSave}
            updateUser={updateUser}
          />
        </TabPanel>
        {/* <TabPanel value={tabValue} index={2} dir={theme.direction}>
            <BusinessOffersComponent businessId={id} />
          </TabPanel> */}
      </SwipeableViews>
    </div>
  )
}

export default EditProfile
