import { useEffect, useState } from "react"
import { UserContext, INITIAL_USER_CONTEXT_VALUE, lightTheme, darkTheme } from "./userStore"
import { observeAuthState } from "../../firebase"
import { User, signOut } from "firebase/auth"
import {
  DocumentData,
  Query,
  Timestamp,
  and,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import { DateTime } from "luxon"
import { auth, db as firestore } from "../../firebase"
import { LocationType, getLocationQuery } from "../../utils"

import { ISponsor } from "./Sponsor"

// import { OffersConfig } from "./OffersConfig"

import { LoadingService } from "../../react-ui-components/components/LoadingService"
import { PopupService } from "../../react-ui-components/components/PopupService"
import { useAuthState } from "react-firebase-hooks/auth"

let userRolesCache: any = null

export interface IDisplayContentConfig {
  addedPointsAnimation: string
  availableLocations: any[]
  copyrightMessage: string
  dateTimeFormat: string
  defaultProfileUrl: string
  id: string
  imageResizeMode: string
  pointAnimationLink: string
  qrCodeIcon: string
  raffleTypes: any[]
  rafflesImageHeight: number
  rafflesScreen: {
    descriptionLength: number
    hideAllActions: boolean
    imageResizeMode: string
    rafflesImageHeight: number
  }
  rafflesSectionImageHeight: number
  sponsorsTitle: string
  sponsorsTitleKey: string
  termIcon: string
  vendorPercentage: string
}

export const AppProvider: React.FC<{ children: any }> = ({ children }) => {
  const [mode, setMode] = useState(INITIAL_USER_CONTEXT_VALUE.mode)
  const [theme, setTheme] = useState(INITIAL_USER_CONTEXT_VALUE.theme)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userInfo, setUserInfo] = useState<any>(null)
  const [ticketsCount, setTicketsCount] = useState(0)
  const [sponsors, setSponsors] = useState<ISponsor[]>([])
  const [isEmailProvider, setIsEmailProvider] = useState(false)
  const [isPhoneProvider, setIsPhoneProvider] = useState(false)
  const [supportInfo, setSupportInfo] = useState({})
  const [displayContentConfig, setDisplayContentConfig] = useState({})
  const [loading, setLoading] = useState(false)
  const [usersCount, setUsersCount] = useState()
  const [user] = useAuthState(auth)
  const [userRoles, setUserRoles] = useState<any[]>([])
  const [userIsAdvanced, setUserIsAdvanced] = useState(false)
  const [usertickets, setUsertickets] = useState<any[]>([])
  const [userPoints, setUserPoints] = useState(0)

  useEffect(() => {
    let updateUserContextInfoUnsubscribe: any = null

    observeAuthState(async (user: User) => {
      updateUserContextInfoUnsubscribe = await updateUserContextInfo(user)
    })
    return () => {
      updateUserContextInfoUnsubscribe && updateUserContextInfoUnsubscribe()
    }
  }, [user])

  useEffect(() => {
    setTheme(mode === "light" ? lightTheme : darkTheme)
  }, [mode])

  async function updateUserContextInfo(user: User) {
    let updateUserTicketsUnsubscribe: any = null
    try {
      userRolesCache = null
      setLoading(true)

      if (user) {
        /*
        "password" – Email & Password authentication
        "phone" – Phone number authentication
        "google.com" – Google Sign-In
        "facebook.com" – Facebook Login
        "twitter.com" – Twitter Login
        "github.com" – GitHub Login
        "microsoft.com" – Microsoft Login (OAuth)
        "apple.com" – Apple Sign-In
        "yahoo.com" – Yahoo Login
        "linkedin.com" – LinkedIn Login (via OAuth)
        "saml.<provider_id>" – SAML identity provider
        "oidc.<provider_id>" – OpenID Connect (OIDC) identity provider
        "anonymous" – Anonymous authentication
        "custom" – Custom authentication via Firebase Admin SDK
        */
        const isEmailProvider = user.providerData.some(
          (provider: { providerId: string }) => provider.providerId === "password",
        )
        setIsEmailProvider(isEmailProvider)

        const isPhoneProvider = user.providerData.some(
          (provider: { providerId: string }) => provider.providerId === "phone",
        )
        setIsPhoneProvider(isPhoneProvider)

        const isLoggedIn = isEmailProvider
          ? !user.isAnonymous && user.emailVerified
          : !user.isAnonymous

        setIsLoggedIn(isLoggedIn)

        if (isLoggedIn) {
          await loadUserRoles(user.uid)
          await loadUser(user.uid)

          const ticketsCount = await getUsersTickets()
          setTicketsCount(ticketsCount)
          updateUserTicketsUnsubscribe = updateUserTickets(user.uid)
        }

        await getUsersStats()
        const supportInfo = await getSupportInfo()
        setSupportInfo(supportInfo)

        const displayContentConfig = await getDisplayContentConfig()
        setDisplayContentConfig(displayContentConfig)
      } else {
        setUserInfo(null)
        setLoading(false)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
    return () => {
      updateUserTicketsUnsubscribe && updateUserTicketsUnsubscribe()
    }
  }

  function updateUserTickets(id: string, raffleId?: string) {
    const result = userTicketsQuery(id, raffleId)
    return onSnapshot(result, {
      next: (snapshot) => {
        setUsertickets(snapshot.docs.map((x) => x.data()))
        setTicketsCount(snapshot.size)
      },
      error: (error) => console.error("There was an error getting updateUserTickets-->", error),
    })
  }

  function resetSponsor() {
    setSponsors([])
  }

  function addSponsor(data: ISponsor, reset: boolean = false) {
    if (data) {
      if (reset || !sponsors) {
        resetSponsor()
      }
      setSponsors((arr) => [data, ...arr])
    }
  }

  function listenForSponsorsChanges(location: LocationType, get?: Function) {
    return onSnapshot(getSponsorRef(location), {
      next: (snapshot) => {
        resetSponsor()
        if (get) {
          get(snapshot)
        } else {
          snapshot.forEach((item: any) => {
            const resultData: ISponsor = item.data()
            resultData.image = resultData.image || ""
            addSponsor(resultData)
          })
        }
      },
      error: (error) => {
        resetSponsor()
        console.error("There was an error getting ListenForSponsorsChanges-->", error)
      },
    })
  }

  function getSponsorDoc(id?: string) {
    const ref = collection(firestore, "sponsors")
    return id ? doc(ref, id) : doc(ref)
  }

  function setPoints(points: number) {
    points = points || 0
    setUserPoints(points)
  }

  async function getUsersStats() {
    const querySnapshot = doc(firestore, "app-configurations/usersStats")

    const ref = await getDoc(querySnapshot)
    const data: any = ref.data()
    // usersCountIcon = data?.icon || {}
    setUsersCount(data?.count || 0)
    // console.log("USERS STATS ----->", data)
    return data
  }

  async function getUsersTickets() {
    const id = user?.uid

    if (!id) {
      return 0
    }

    try {
      const querySnapshot = collection(firestore, "users", id, "tickets")

      const result = await getCountFromServer(
        query(
          querySnapshot,
          and(
            where("expirationDate", ">=", Timestamp.now().toMillis()),
            where("disabled", "==", false),
          ),
        ),
      )

      return result?.data()?.count || 0
    } catch (error) {
      console.log("getUsersTickets----error---->", error)
      return 0
    }
  }

  async function loadUserRoles(uid?: string) {
    uid = uid || user?.uid
    if (!uid) {
      return
    }
    if (!userRolesCache) {
      userRolesCache = {}
    }

    if (userRolesCache[uid]) {
      setUserRoles(userRolesCache[uid])
      setUserIsAdvanced(!!(userRoles || []).find((x) => /admin|super|vendor/i.test(x.name)))
    }
    const userRolesDoc = await getDocs(collection(firestore, `users/${uid}/roles`))

    userRolesCache[uid] = userRolesDoc.docs.map((x) => x.data() as any)
    setUserRoles(userRolesCache[uid])
    setUserIsAdvanced(!!(userRoles || []).find((x) => /admin|super|vendor/i.test(x.name)))
  }

  async function loadUser(uid?: string) {
    try {
      uid = uid || auth?.currentUser?.uid
      setLoading(true)
      if (uid) {
        const userDoc = await getDoc(doc(firestore, "users", uid))
        const data = userDoc.data()
        const user = data as any
        setUserInfo(user)
        updateUserContextInfo(user)
        setPoints(user?.points || 0)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  async function setUserData(updatedUser: any) {
    const user = auth.currentUser
    if (user) {
      await setDoc(doc(firestore, `users/${user.uid}`), updatedUser, {
        merge: true,
      })
      setUserInfo({ ...userInfo, ...updateUser })
    }
  }

  async function updateUser(updatedUser: any) {
    const user = auth.currentUser
    if (user) {
      await updateDoc(doc(firestore, `users/${user.uid}`), updatedUser)
      setUserInfo({ ...user, ...updatedUser })
    }
  }

  async function deleteUser() {
    const user = auth.currentUser
    if (user) {
      await user.delete()
      await deleteDoc(doc(firestore, `users/${user.uid}`))
      setUserInfo(null)
    }
  }

  async function handleLogout() {
    LoadingService.setLoading(true)
    try {
      await signOut(auth)
    } catch (error: any) {
      PopupService.pushPopup(error?.message || error, "Error")
    } finally {
      LoadingService.setLoading(false)
    }
  }

  async function getThisMonthOffers(sponsorId: string): Promise<any> {
    try {
      const currentMonth = DateTime.now().toFormat("LLyyyy")
      // console.log('getThisMonthOffers ----currentMonth------->', currentMonth)
      // console.log('getThisMonthOffers ----date------->', date)
      // console.log('getThisMonthOffers ----sponsorId------->', sponsorId)
      // console.log('currentMonth---->', currentMonth);
      const ref = query(
        collection(firestore, "sponsors", sponsorId, "offers", currentMonth, "offers"),
        orderBy("order"),
        where("disabled", "==", false),
      )
      ///sponsors/8IAQdwz85Qokfmifsd02/offers/042024/offers
      // const found = self.sponsors.find(x => x.id === id);
      // const data = result.data()
      const arr: any[] = []
      const docsRef = await getDocs(ref)
      docsRef.forEach((element) => {
        const data = element.data()
        // console.log("DATA---->", data)
        arr.push(data)
      })
      return arr
    } catch (error) {
      console.log("There was an error trying to load sponsors offers", error)
    }
  }

  return (
    <UserContext.Provider
      value={{
        ...INITIAL_USER_CONTEXT_VALUE,
        theme,
        mode,
        setMode,
        user: (user || null) as any,
        userInfo,
        isLoggedIn,
        loadUser,
        updateUser,
        deleteUser,
        setPoints,
        addSponsor,
        listenForSponsorsChanges,
        resetSponsor,
        getThisMonthOffers,
        handleLogout,
        setUserData,
        getUsersTickets,
        ticketsCount,
        getSponsorDoc,
        isEmailProvider,
        isPhoneProvider,
        supportInfo,
        displayContentConfig,
        loading,
        usersCount: usersCount || 0,
        userIsAdvanced,
        sponsors,
        usertickets,
        points: userPoints,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

function getSponsorRef(location: LocationType) {
  const arr = getLocationQuery(location, false)
  //query(collection(db, `sponsors/${sponsorId}/services`), and(...arr))
  return query(collection(firestore, "sponsors"), and(...arr), orderBy("order"))
}

async function getDisplayContentConfig() {
  const querySnapshot = doc(firestore, "app-configurations/display-content-config")

  const ref = await getDoc(querySnapshot)
  const data: IDisplayContentConfig = ref.data() as any

  return data
}

async function getSupportInfo() {
  const querySnapshot = doc(firestore, "app-configurations/technicalSupport")

  const ref = await getDoc(querySnapshot)
  const data: any = ref.data()

  return data
}

export function userTicketsQuery(id: string, raffleId?: string): Query<DocumentData, DocumentData> {
  const querySnapshot = collection(firestore, "users", id, "tickets")

  const queryRef = query(
    querySnapshot,

    raffleId
      ? and(
          where("expirationDate", ">=", Timestamp.now().toMillis()),
          where("disabled", "==", false),
          where("raffleId", "==", raffleId),
        )
      : and(
          where("expirationDate", ">=", Timestamp.now().toMillis()),
          where("disabled", "==", false),
        ),
  )

  return queryRef
}
