import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useUserStore } from './components/stores/userStore';
import { db } from './firebase';

const LanguageSelector: React.FC<{ onChange?: any; useValue?: boolean, value?: string }> = ({
  onChange,
  useValue,
  value = ''
}: any) => {
  const { i18n, t } = useTranslation();
  const { user } = useUserStore();

  const currentLanguage = value || i18n.language || 'en';
  const [availableLangs, setAvailableLangs] = useState([
    { code: 'en', value: 'English' },
    { code: 'es', value: 'Spanish' },
  ]);
  const [lang, setLang] = useState(useValue ? 'English' : 'en');

  useEffect(() => {
    async function getLangs() {
      try {
        const querySnapshot = doc(db, 'app-configurations/languages');

        const docRef = await getDoc(querySnapshot);

        const kv: any = docRef.data();

        setAvailableLangs(Object.keys(kv).map((k) => kv[k]));
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    }

    getLangs();

    let language = currentLanguage;
    if (/.+-.+/.test(currentLanguage)) {
      const result = /(.+)-.+/.exec(currentLanguage)?.[1] || 'en';
      language = useValue
        ? availableLangs.find((x) => x.code === result)?.value || result
        : result;
    } else {
      language = useValue
        ? availableLangs.find((x) => x.code === currentLanguage)?.value ||
          currentLanguage
        : currentLanguage;
    }

    if (onChange) {
      setLang(user?.language || currentLanguage);
    } else {
      setLang(language);
    }
  }, [currentLanguage, useValue]);

  const handleLanguageChange = (event: any) => {
    if (onChange) {
      setLang(event.target.value);
      onChange(event);
    } else {
      const selectedLanguage = (event.target.value as string) || 'en';
      i18n.changeLanguage(selectedLanguage);
    }
  };

  return (
    <Box sx={{ minWidth: 120, marginTop: 2, width: '100%' }}>
      <FormControl fullWidth>
        <InputLabel id="language-selector-label">
          {t('profile.language')}
        </InputLabel>
        <Select
          name="language"
          className="flex flex-column gap-10"
          label={t('profile.language')}
          labelId="language-selector-label"
          value={lang}
          onChange={handleLanguageChange}
        >
          {availableLangs.map(({ code, value }, i) => (
            <MenuItem key={i} value={useValue ? value : code}>
              {t(value)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
/*
  <FormControl fullWidth>
            <InputLabel>Country</InputLabel>
            <Select
              className="flex flex-column gap-10"
              value={selectedCountry}
              label="Country"
              onChange={(val) => {
                const country: any = val?.target?.value || "US"
                setSelectedCountry(country)
                setUserSelectedFilter((val: any) => ({ ...val, country }))
              }}
            >
              {Object.keys(availableLocations).map((country: any, i: number) => (
                <MenuItem key={`${country}-${i}`} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
*/
export default LanguageSelector;
