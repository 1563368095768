import { Storefront } from "@mui/icons-material"
import { Button, Typography } from "@mui/material"
import { DownloadButtons } from "./components/Logo"
import { Link } from "react-router-dom"
import React from "react"
import DarkModeIcon from "@mui/icons-material/DarkMode"
// import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import LanguageSelector from "./LanguageSelector"
import LightModeIcon from "@mui/icons-material/LightMode"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import SocialMediaLinks from "./components/SocialMediaLinks"
import { Sponsors } from "./components/Sponsors"
import Toolbar from "@mui/material/Toolbar"
import { UserQuickInfo } from "./UserQuickInfo"
import { UsersCounter } from "./components/UsersCounter"
import WysiwygIcon from "@mui/icons-material/Wysiwyg"
// import { useColorScheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import { PopupService } from "./react-ui-components/components/PopupService"
import { SupportInfo } from "./components/SupportInfo"
import { useUserStore } from "./components/stores/userStore"

export const DrawerContent: React.FC = () => {
  const { mode, setMode, displayContentConfig } = useUserStore()
  const { t } = useTranslation()

  return (
    <div className="flex flex-col h-full">
      <Toolbar>
        <div className="flex w-full flex-col break-all">
          <UserQuickInfo />
        </div>
      </Toolbar>
      <Divider />

      <div className="flex flex-col justify-between h-[80vh]">
        <List>
          <ListItem>
            <div className="flex justify-end w-full">
              <UsersCounter variant="text" left={false} size="small"></UsersCounter>
            </div>
          </ListItem>

          <ListItem>
            {displayContentConfig?.displayDownload ? (
              <div className="flex flex-col gap-1">
                <Typography> {t`Download the App`}</Typography>
                <DownloadButtons revertColor />
              </div>
            ) : null}
          </ListItem>

          <Link
            to=""
            onClick={() =>
              PopupService.setPopup(<Sponsors hideDiv centerTitle />, "", { height: "100vh" })
            }
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Storefront />
                </ListItemIcon>
                <ListItemText primary={t("Sponsors")} />
              </ListItemButton>
            </ListItem>
          </Link>
        </List>

        <div className="flex flex-col justify-end h-full">
          <List>
            <ListItem>
              <SocialMediaLinks />
            </ListItem>

            <ListItem>
              <SupportInfo className="m-1" />
            </ListItem>

            <ListItem>
              <Button
                sx={{ marginTop: 2 }}
                onClick={() => {
                  setMode(mode === "light" ? "dark" : "light")
                }}
              >
                {mode === "light" ? (
                  <LightModeIcon />
                ) : mode === "system" ? (
                  <WysiwygIcon />
                ) : (
                  <DarkModeIcon />
                )}
              </Button>

              <LanguageSelector />
            </ListItem>
          </List>
        </div>
      </div>
    </div>
  )
}
