import {
  Button,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Icon,
} from "@mui/material"
import { doc, onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"
import { auth, db, functions } from "../firebase"
import { colors, typography } from "../theme"
import { formatCash, formatNumber } from "../utils"

import { useAuthState } from "react-firebase-hooks/auth"
import { PopupService } from "../react-ui-components/components/PopupService"
import { useUserStore } from "./stores/userStore"
import { Image } from "./Tickets/Image"
import { Login } from "./LoginScreen/Login"
import { useTranslation } from "react-i18next"
import { useRaffleContext } from "../contexts/RaffleContext"
import { format } from "./getLuxonDate"
import htmr from "htmr"
import { CopyAll } from "@mui/icons-material"
import { httpsCallable } from "firebase/functions"

export const generateOneTimeUserCode = httpsCallable(functions, "generateOneTimeUserCode")
export const retrieveOneTimeUserCode = httpsCallable(functions, "retrieveOneTimeUserCode")
export const createNewTicketWithPoints = httpsCallable(functions, "createNewTicketWithPoints")

export default function ExchangePointsScreen() {
  const { points, isLoggedIn, setPoints } = useUserStore()
  const { t } = useTranslation()

  const { raffles } = useRaffleContext()
  const [userCode, setUserCode] = useState<string>("")
  const [sendPointsCount, setSendPointsCount] = useState(0)
  const [generatedCode, setGeneratedCode] = useState<string>("")
  const [expirationTime, setExpirationTime] = useState<Date | null>(null)
  const [timeLeft, setTimeLeft] = useState<string>("")
  const [originalMessage, setOriginalMessage] = useState<string>("")
  const [remainingTime, setRemainingTime] = useState<number | null>(null)
  const [selectedRaffle, setSelectedRaffle] = useState<string | null>(null)
  const [ticketsCount, setTicketsCount] = useState<number>(1)
  const hasPoints = points > 0
  const hasRaffles = raffles.length > 0
  const affordableRaffles = raffles.filter((raffle) => raffle.points > 0 && raffle.points <= points)
  const hasAffordableRaffles = affordableRaffles.length > 0

  useEffect(() => {
    if (!expirationTime) return

    const interval = setInterval(() => {
      const now = new Date()
      const diff = expirationTime.getTime() - now.getTime()

      if (diff <= 0) {
        clearInterval(interval)
        setGeneratedCode("")
        setExpirationTime(null)
        setTimeLeft("")
      } else {
        const minutes = Math.floor(diff / 60000)
        const seconds = Math.floor((diff % 60000) / 1000)
        setTimeLeft(`${minutes}:${seconds.toString().padStart(2, "0")}`)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [expirationTime])

  useEffect(() => {
    if (remainingTime === null) return

    const interval = setInterval(() => {
      setRemainingTime((prev) => {
        if (prev === null || prev <= 1) {
          clearInterval(interval)
          setGeneratedCode("")
          setExpirationTime(null)
          setTimeLeft("")
          setOriginalMessage("")
          return null
        }
        return prev - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [remainingTime])

  useEffect(() => {
    if (affordableRaffles.length === 1) {
      setSelectedRaffle(affordableRaffles[0].id)
    }
  }, [affordableRaffles])

  const generateUserCode = async () => {
    if (remainingTime !== null) {
      const minutes = Math.floor(remainingTime / 60)
      const seconds = remainingTime % 60
      const translatedMessage = t(originalMessage, {
        NUMBER: `${minutes}:${seconds.toString().padStart(2, "0")}`,
      })
      PopupService.pushPopup(translatedMessage)
      return
    }

    if (!isLoggedIn) {
      PopupService.pushPopup(t("mustBeLoggedInToGenerateCode"), t("Error"))
      return
    }

    if (points <= 0) {
      PopupService.pushPopup(t("insufficientPointsToGenerateCode"), t("Error"))
      return
    }

    try {
      const result: {
        data: { code: string; expirationTime: string; message?: string; remainingTime: number }
      } = (await generateOneTimeUserCode()) as any

      setGeneratedCode(result.data.code)
      setExpirationTime(new Date(result.data.expirationTime))
      setRemainingTime(result.data.remainingTime)

      if (result.data.message) {
        setOriginalMessage(result.data.message)
        const minutes = Math.floor(result.data.remainingTime / 60)
        const seconds = result.data.remainingTime % 60
        const translatedMessage = t(result.data.message, {
          NUMBER: `${minutes}:${seconds.toString().padStart(2, "0")}`,
        })
        PopupService.pushPopup(translatedMessage)
      }
      //  else {
      //   PopupService.pushPopup(t("userCodeGenerated"))
      // }
    } catch (error) {
      PopupService.pushPopup(t("errorGeneratingUserCode"), t("Error"))
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedCode)
    PopupService.pushPopup(t("copiedToClipboard"))
  }

  return (
    <Box
      sx={{
        backgroundColor: "#00985F",
        padding: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Image
          className="m-5"
          src={require("../assets/icons/FacilCoin2.png")}
          width={40}
          alt="winfacil coin"
        />
        {hasPoints && (
          <Typography
            variant="h4"
            color="white"
            align="center"
            sx={{
              wordWrap: "break-word",
              fontSize: { xs: "1.25rem", sm: "2.125rem" },
            }}
          >
            {points.toFixed(15)}
          </Typography>
        )}
      </Box>
      <Typography
        variant="h6"
        color="white"
        align="center"
        sx={{ wordWrap: "break-word", width: "100%" }}
      >
        {t("points")}
      </Typography>

      {!hasPoints && (
        <Typography variant="body2" color="white" align="center">
          {t("noPointsMessage")}
        </Typography>
      )}

      {hasPoints && (
        <Box sx={{ marginTop: 2, width: "100%" }}>
          <Box>
            <Box className="flex flex-row gap-1">
              <InputLabel className="my-3" id="raffle-select-label" sx={{ color: "white" }}>
                {t("exchangePoints")}:
              </InputLabel>
              <Button
                size="small"
                variant="text"
                onClick={() => {
                  PopupService.pushPopup(t("obtainPointsInfo"))
                }}
              >
                <Icon className="text-amber-200">info</Icon>
              </Button>
            </Box>

            <Box className="flex gap-1" sx={{ flexDirection: { xs: "column", sm: "row" } }}>
              <TextField
                type="number"
                label={`${t("Enter point count")}:`}
                value={sendPointsCount}
                onChange={(e) => {
                  const value = +e.target.value
                  if (value <= points) {
                    setSendPointsCount(value)
                  } else {
                    PopupService.pushPopup(
                      t("You cannot enter more points than you have available."),
                      t("Error"),
                    )
                  }
                }}
                // InputProps={{
                //   style: { color: "white" },
                // }}
                // InputLabelProps={{
                //   style: { color: "white" },
                // }}
                slotProps={{
                  inputLabel: {
                    style: { color: "white" },
                  },
                  input: {
                    style: { color: "white" },
                  },
                }}
              />
              <Box
                className="flex gap-1 relative"
                sx={{ flexDirection: { xs: "column", sm: "row" } }}
              >
                <TextField
                  label={`${t("Enter user code")}:`}
                  value={userCode}
                  onChange={(e) => {
                    setUserCode(e.target.value)
                  }}
                  sx={{ width: { xs: "100%", sm: "auto" } }}
                  // InputProps={{
                  //   style: { color: "white" },
                  // }}
                  slotProps={{
                    inputLabel: {
                      style: { color: "white" },
                    },
                    input: {
                      style: { color: "white" },
                    },
                  }}
                  // InputLabelProps={{
                  //   style: { color: "white" },
                  // }}
                />
                <Button
                  disabled={!/^\d{9}-\d{1}$/.test(userCode) || sendPointsCount <= 0}
                  variant="contained"
                  className=""
                  onClick={async () => {
                    try {
                      await retrieveOneTimeUserCode({ code: userCode, points: sendPointsCount })
                      setUserCode("")
                      setSendPointsCount(0)
                      setGeneratedCode("")
                      setExpirationTime(null)
                      setTimeLeft("")
                      setOriginalMessage("")
                      setRemainingTime(null)
                      PopupService.pushPopup(t("pointsSentSuccessfully"))
                    } catch (error: any) {
                      setUserCode("")
                      setSendPointsCount(0)
                      setGeneratedCode("")
                      setExpirationTime(null)
                      setTimeLeft("")
                      setOriginalMessage("")
                      setRemainingTime(null)
                      PopupService.pushPopup(
                        error.message ? t(error.message) : t("errorRetrievingUserCode"),
                        t("Error"),
                      )
                    }
                  }}
                  sx={{ width: { xs: "100%", sm: "auto" } }}
                >
                  {t("send")}
                </Button>
              </Box>
            </Box>
          </Box>

          <Box className="mt-5">
            {hasRaffles ? (
              hasAffordableRaffles ? (
                <Box>
                  <InputLabel id="raffle-select-label" sx={{ color: "white" }}>
                    {t("selectRaffleToRedeemPointsFor")}
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      labelId="raffle-select-label"
                      id="raffle-select"
                      value={selectedRaffle || ""}
                      onChange={(e) => setSelectedRaffle(e.target.value)}
                      sx={{ backgroundColor: "white", borderRadius: 1 }}
                    >
                      {affordableRaffles.map((raffle) => (
                        <MenuItem key={raffle.id} value={raffle.id}>
                          <Box
                            sx={{ display: "flex", alignItems: "center", gap: 2, width: "100%" }}
                          >
                            <Image
                              src={raffle.raffleImages[0]}
                              alt={raffle.title}
                              style={{ width: 50, height: 50, borderRadius: 4 }}
                            />
                            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                              <Typography variant="body1" fontWeight="bold">
                                {raffle.title ? htmr(raffle.title) : t("noTitle")}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {t("date")}: {format(raffle.raffleDate, "x", true)}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {t("points")}: {raffle.points}
                              </Typography>
                            </Box>
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedRaffle && (
                    <Box
                      className="flex flex-row gap-1 items-center justify-center"
                      sx={{ marginTop: 2, textAlign: "center" }}
                    >
                      <TextField
                        type="number"
                        label={t("Enter number of tickets")}
                        value={ticketsCount}
                        onChange={(e) => {
                          const value = +e.target.value
                          const raffle = affordableRaffles.find((r) => r.id === selectedRaffle)
                          if (raffle && value * raffle.points <= points) {
                            setTicketsCount(value)
                          } else {
                            PopupService.pushPopup(t("insufficientPointsForTickets"), t("Error"))
                          }
                        }}
                        sx={{ marginBottom: 2 }}
                        slotProps={{
                          inputLabel: {
                            style: { color: "white" },
                          },
                          input: {
                            style: { color: "white" },
                          },
                        }}
                      />
                      <Button
                      className="mb-4"
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          const raffle = affordableRaffles.find((r) => r.id === selectedRaffle)
                          if (raffle) {
                            try {
                              await createNewTicketWithPoints({
                                raffleId: raffle.id,
                                ticketsCount,
                              })
                              setPoints(points - raffle.points * ticketsCount)
                              PopupService.pushPopup(t("raffleRedeemedSuccessfully"))
                              setSelectedRaffle(null)
                              setTicketsCount(1)
                            } catch (error) {
                              PopupService.pushPopup(t("errorRedeemingRaffle"), t("Error"))
                            }
                          }
                        }}
                        disabled={ticketsCount <= 0}
                      >
                        {t("redeem")}
                      </Button>
                    </Box>
                  )}
                </Box>
              ) : (
                <Typography variant="body2" color="white" align="center" sx={{ marginTop: 2 }}>
                  {t("notEnoughPointsForRaffles")}
                </Typography>
              )
            ) : (
              <Typography variant="body2" color="white" align="center" sx={{ marginTop: 2 }}>
                {t("noRafflesAvailable")}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {hasPoints && (
        <Box sx={{ marginTop: 4, width: "100%", textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={generateUserCode}
            sx={{ marginBottom: 2 }}
          >
            {t("generateUserCode")}
          </Button>
          {generatedCode && (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
              <Typography variant="body1" color="white">
                {generatedCode}
              </Typography>
              <Icon
                component={CopyAll}
                sx={{ color: "white", cursor: "pointer" }}
                onClick={copyToClipboard}
              />
              {timeLeft && (
                <Typography variant="body2" color="white">
                  {t("expiresIn")}: {timeLeft}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

// function validatePoints(selectedPoints: number, selectedRaffle: any, points: number) {
//   return selectedPoints * selectedRaffle.points > points
// }

// function getRaffles(points: number, rafflePoints: number) {
//   return points * rafflePoints
// }
