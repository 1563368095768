import {
  Favorite,
  FavoriteBorder,
  ThumbUp,
  ThumbUpOffAlt,
  ThumbUpOffAltOutlined,
} from "@mui/icons-material"
import { CircularProgress, IconButton, Stack, Typography } from "@mui/material"
import { User } from "firebase/auth"
import {
  CollectionReference,
  doc,
  DocumentData,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore"
import React, { useEffect, useState } from "react"

import { LoadingService } from "./LoadingService"
import { IRaffle } from "./models"
import { formatCash } from "./utils"
import { useTranslation } from "react-i18next"
import { Image } from "../../components/Tickets/Image"

export interface LikeComponentProps {
  handleLike: (raffleId: string) => Promise<void>
  raffle: IRaffle
  user: User
  getLikesRef: (raffleId: string) => CollectionReference<DocumentData, DocumentData>
}
export const LikeComponent: React.FC<LikeComponentProps> = (props) => {
  const { t } = useTranslation()

  const { handleLike, raffle, getLikesRef, user } = props

  const [liked, setLiked] = useState<boolean>(false)
  const [likeCount, setLikeCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const fetchLikes = async (likesRef: CollectionReference<DocumentData, DocumentData>) => {
    if (!user || !likesRef) {
      return
    }

    setLoading(true)
    try {
      const likeDoc = await getDoc(doc(likesRef, user.uid))
      setLiked(likeDoc.exists() ? likeDoc.data().liked : false)
    } catch (error: any) {
      LoadingService.setLoading(true, error?.message || error, true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!raffle || !getLikesRef) {
      return
    }

    const likesRef = getLikesRef(raffle.id)
    const likesQuery = query(likesRef, where("liked", "==", true))

    const updateLikes = async () => {
      await fetchLikes(likesRef)
    }

    updateLikes()

    return onSnapshot(likesQuery, {
      next: async (snap) => {
        const likeCount = snap.size
        setLikeCount(likeCount)

        if (likeCount > 0) {
          await fetchLikes(likesRef)
        } else {
          setLiked(false)
        }

        LoadingService.setLoading(false)
      },
      error: (data) => {
        LoadingService.setLoading(true, data?.message || data, true)
      },
      complete: () => {
        LoadingService.setLoading(false)
      },
    })
  }, [raffle, user])

  return (
    <Stack direction="row" spacing={0} alignItems="center" className="items-baseline">
      <IconButton onClick={loading ? undefined : () => handleLike(raffle.id)}>
        {loading ? (
          <CircularProgress size={24} />
        ) : liked ? (
          raffle?.winTicketPerLike ? (
            <Image src={require("../../assets/logo-box.png")} width={35} height={35} />
          ) : (
            <ThumbUp fontSize="large" color="primary" />
          )
        ) : raffle?.winTicketPerLike ? (
          <Image src={require("../../assets/logo-box.png")} width={35} height={35} />
        ) : (
          <ThumbUpOffAltOutlined fontSize="large" />
        )}
      </IconButton>

      <Typography className="m-[-7px]" variant="body2">{`${
        raffle?.winTicketPerLike ? "Win" : t("Like")
      } ${formatCash(likeCount)}`}</Typography>
    </Stack>
  )
}
