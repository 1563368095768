import Clear from "@mui/icons-material/Clear"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  useMediaQuery,
} from "@mui/material"
import React, { createContext, useContext, useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

export const PopupService = {
  pushPopup: (popupVal: any, titleVal?: string | null, style?: any) =>
    console.log("PopupService--pushPopup-->", popupVal),
  setPopup: (...args: any) => console.log("PopupService--setPopup-->", args),
  setConfirm: (...args: any) => console.log("PopupService--setPopup-->", args),
  getPopup: () => console.log("getPopup---->"),
  setTitle: (title: string) => console.log("PopupService--title-->", title),
  pop: () => console.log("PopupService--pop-->"),
}

const PopupContext = createContext({ popup: null, title: "" })

export const usePopup = () => {
  return useContext(PopupContext)
}
let popupCount = 1
const BootstrapDialog = styled(Dialog)(({ theme, style }) => {
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  return {
    "& .MuiPaper-root": {
      width: "100%",
      margin: matches ? 30 : 5,
      height: style?.height,
      marginRight: matches ? 60 : 0,
      maxWidth: matches ? 600 : "100%",

      ...style,
    },
  }
})

export const PopupProvider = (props: { children: any; defaultPopup?: any }) => {
  const { children, defaultPopup = null } = props
  const [popup, setPopup] = useState<any>(defaultPopup)

  const [title, setTitle] = useState("")
  useEffect(() => {
    PopupService.setPopup = (
      val: any,
      titleVal?: string | { [key: string]: any },
      style?: any,
      onClosed?: () => void,
    ) => {
      const styleVal = style ? style : typeof titleVal !== "string" ? {} : titleVal
      setPopup([
        <ModalPopup
          key={popupCount}
          title={typeof titleVal === "string" ? titleVal : title || ""}
          popup={val}
          styleValue={styleVal}
          onClosed={onClosed}
        />,
      ])
    }
    PopupService.getPopup = () => popup
    PopupService.setTitle = setTitle

    PopupService.pushPopup = (popupVal: any, titleVal?: string | null, style?: any) => {
      setPopup((val: any) => {
        const key = popupCount++

        return [
          ...(Array.isArray(val) ? val : [val]),
          <ModalPopup
            style={style}
            key={`${key}-${titleVal || ""}`}
            title={titleVal || title}
            popup={popupVal}
          />,
        ]
      })
    }

    PopupService.pop = () => {
      setPopup((val: any[]) => {
        popupCount--
        val.pop()
        return [...val]
      })
    }

    PopupService.setConfirm = (popupVal: any, ...rest: any[]) => {
      setPopup((val: any) => {
        const key = popupCount++
        const titleVal: string | null = rest?.[0]
        const style: any = rest?.[1]

        const callback =
          rest?.[1] || typeof titleVal === "function"
            ? titleVal
            : typeof style === "function"
            ? style
            : null

        return [
          ...(Array.isArray(val) ? val : [val]),
          <ModalPopup
            style={style}
            key={`${key}-${titleVal || ""}`}
            title={titleVal || title}
            popup={popupVal}
            showOk
            showCancel
            onClosed={callback}
          />,
        ]
      })
    }
  }, [popup, title])

  // return null
  return (
    <PopupContext.Provider value={{ popup, title }}>
      {children}

      {popup}
    </PopupContext.Provider>
  )
}

export function ModalPopup(props: {
  title: string
  popup: any
  type?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
  style?: any
  styleValue?: any
  modalVisible?: boolean
  showOk?: boolean
  showCancel?: boolean
  onClosed?: (arg?: any) => void
  setModalVisible?: any
  onOk?: any
}) {
  const { t } = useTranslation()

  const {
    title,
    popup,
    type,
    style,
    showOk,
    styleValue,
    onClosed,
    showCancel,
    modalVisible: visible,
    setModalVisible: setVisible,
    onOk,
  } = props
  const defaultVisibility = typeof visible === "undefined" ? true : !!visible
  const [modalVisible, setModalVisible] = useState(defaultVisibility)

  useEffect(() => {
    setModalVisible(defaultVisibility)
    setVisible && setVisible(defaultVisibility)
  }, [popup])

  const styleValProp = typeof styleValue === "string" ? {} : styleValue
  const className = typeof styleValue === "string" ? styleValue : ""
  // const classes = className.split(" ").reduce((prev, cur) => {
  //   return {
  //     [cur]: cur,
  //     ...prev,
  //   }
  // }, {})
  if (popup === null) {
    return null
  }

  return (
    <BootstrapDialog
      open={modalVisible}
      style={styleValProp || style}
      className={className}
      onClose={onClosed}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            onClosed && onClosed()
            setModalVisible(false)
            setVisible && setVisible(false)
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Clear />
        </IconButton>
      </div>
      <DialogContent style={style}>
        {React.Children.map(popup, (child, key) => {
          if (React.isValidElement(child)) {
            const popupProps: any = {
              close: () => setModalVisible(false),
              key,
            }

            return React.cloneElement(child, popupProps)
          }

          return child
        })}
        {/* {!!showOk && (
          <Button
            fullWidth
            size="medium"
            variant="outlined"
            color={type}
            onClick={() => setModalVisible(false)}
          >
            OK
          </Button>
        )} */}
      </DialogContent>
      <DialogActions>
        {!!showCancel && (
          <Button
            fullWidth
            color="warning"
            size="medium"
            variant="outlined"
            onClick={() => {
              onClosed && onClosed("Cancel")
              setModalVisible(false)
              setVisible && setVisible(false)
            }}
          >
            {t("cancel")}
          </Button>
        )}

        {!!showOk && (
          <Button
            fullWidth
            size="medium"
            variant="outlined"
            color={type}
            onClick={() => {
              onClosed && onClosed("OK")
              setModalVisible(false)
              setVisible && setVisible(false)
              onOk && onOk()
            }}
          >
            {t("ok")}
          </Button>
        )}
      </DialogActions>
    </BootstrapDialog>
  )
}
