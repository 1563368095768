import React, { useState } from "react"
import { Logo } from "../Logo"
import { Box, Button, Divider, FormControl, FormLabel, TextField, Typography } from "@mui/material"
import { EmailComponent } from "../../react-ui-components/components/EmailComponent"
import { PasswordComponent } from "../../react-ui-components/components/PasswordComponent"
import { Apple, Google, Phone } from "@mui/icons-material"
import { PopupService } from "../../react-ui-components/components/PopupService"
import { useTranslation } from "react-i18next"
import ForgotPasswordPage from "../ForgotPasswordPage"
import { LoadingService } from "../../react-ui-components/components/LoadingService"
import {
  getMultiFactorResolver,
  OAuthProvider,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth"
import { auth, googleProvider } from "../../firebase"
import { useNavigate } from "react-router-dom"
import { load } from "../../react-ui-components/utils/service"
import { PhoneInput } from "react-international-phone"
import Register from "../Register"
import { MuiPhone } from "../MuiPhone"

export const Login: React.FC = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const navigate = useNavigate()

  return (
    <>
      <Box className="flex flex-col gap-5 mt-5 justify-center">
        <Logo />
        <Typography className="text-center" variant="h5">
          {t("Welcome, please sign in to continue")}
        </Typography>
        <Typography className="text-center" variant="subtitle2">
          {t("To The Platform of Great Surprises")}
        </Typography>
        <Button
          startIcon={<Apple />}
          color="info"
          variant="outlined"
          onClick={async () => {
            const provider = new OAuthProvider("apple.com")
            await load(
              async () => await signInWithPopup(auth, provider),
              () => {
                navigate("/")
                PopupService.setPopup(null)
              },
            )
          }}
        >
          {t("Sign In With Apple")}
        </Button>
        <Button
          startIcon={<Google />}
          color="info"
          variant="outlined"
          onClick={async () => {
            await load(
              async () => await signInWithPopup(auth, googleProvider),
              () => {
                navigate("/")
                PopupService.setPopup(null)
              },
            )
          }}
        >
          {t("Sign In With Google")}
        </Button>
        <Button
          startIcon={<Phone />}
          color="info"
          variant="outlined"
          onClick={() => PopupService.pushPopup(<PhoneLogin />)}
        >
          {t("Sign In With Phone")}
        </Button>
        <Divider orientation="horizontal">or</Divider>
      </Box>

      <Box className="flex flex-col gap-5 mt-5">
        <EmailComponent value={email} setValue={setEmail} />
        <PasswordComponent value={password} setValue={setPassword} />
        <Button
          variant="contained"
          fullWidth
          color="success"
          onClick={() => {
            handleLogin(email, password, navigate)
          }}
        >
          {t("Sign In")}
        </Button>
      </Box>

      <div id="recaptcha-container"></div>
      <Box display="flex" justifyContent="space-between" width="100%" mt={2}>
        <Button
          color="info"
          onClick={() => {
            PopupService.pushPopup(<ForgotPasswordPage />)
          }}
        >
          {t("forgotPassword")}
        </Button>
        <Button
          color="info"
          onClick={() => {
            PopupService.setPopup(<Register />)
          }}
        >
          {t("register")}
        </Button>
      </Box>
    </>
  )
}

const handleMfaSignIn = async (resolver: any, setMfaVerificationId: any) => {
  const phoneAuthProvider = new PhoneAuthProvider(auth)
  const appVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
    size: "invisible",
    callback: (response: any) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
    },
  })
  LoadingService.setLoading(true)

  try {
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      },
      appVerifier,
    )
    setMfaVerificationId && setMfaVerificationId(verificationId)
  } catch (error) {
    console.error("Error during MFA sign-in", error)
  } finally {
    LoadingService.setLoading(false)
  }
}

const handleLogin = async (
  email: string,
  password: string,
  navigate: any,
  setMfaError?: any,
  setMFAVerificationId?: any,
) => {
  LoadingService.show()
  try {
    await signInWithEmailAndPassword(auth, email, password)

    navigate("/")
    PopupService.setPopup(null)
  } catch (error: any) {
    if (error.code === "auth/multi-factor-auth-required") {
      handle2FA(error)
      setMfaError(error)
      const resolver = getMultiFactorResolver(auth, error)
      handleMfaSignIn(resolver, setMFAVerificationId)
    } else {
      let errorMessage = "Failed to log in. Please try again."
      if (error.code === "auth/user-not-found") {
        errorMessage = "No account found with this email."
      } else if (error.code === "auth/wrong-password") {
        errorMessage = "Incorrect password. Please try again."
      } else if (error.code === "auth/user-disabled") {
        errorMessage = "This account has been disabled. Contact support."
      }

      PopupService.pushPopup(errorMessage)
    }
  } finally {
    LoadingService.hide()
  }
}

const PhoneLogin: React.FC = () => {
  const [confirmationResult, setConfirmationResult] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [otp, setOtp] = useState("")
  const [mfaError, setMfaError] = useState(null)
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div id="recaptcha-container">
      <FormControl variant="outlined" fullWidth>
        <FormLabel className="mb-5">{t("enterPhoneNumber")}</FormLabel>
        <MuiPhone
          value={phoneNumber}
          onChange={(phone) => {
            console.log("PhoneInput---->", phone)
            setPhoneNumber(phone)
          }}
        />
      </FormControl>

      <Button
        variant="outlined"
        color="success"
        fullWidth
        onClick={() => load(() => handlePhoneSignIn(phoneNumber, t, setConfirmationResult))}
        sx={{ mt: 2 }}
      >
        {t("Sign In With Phone")}
      </Button>

      {confirmationResult && (
        <>
          <TextField
            label={t("confirmCode")}
            variant="outlined"
            margin="normal"
            fullWidth
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() =>
              handleConfirmPhoneOtp(confirmationResult, otp, () => {
                PopupService.setPopup(null)
                navigate("/")
              })
            }
            sx={{ mt: 2 }}
          >
            {t("confirm")}
          </Button>
        </>
      )}
    </div>
  )
}

const handle2FA = (error: any) => {
  const recaptchaVerifier = new RecaptchaVerifier(auth, "sign-in-button", {
    size: "invisible",
    callback: function (response: any) {
      // reCAPTCHA solved, you can proceed with
      // phoneAuthProvider.verifyPhoneNumber(...).
      // onSolvedRecaptcha();

      // The user is a multi-factor user. Second factor challenge is required.
      const resolver = getMultiFactorResolver(auth, error)

      // console.log('signInWithEmailAndPassword---Error--resolver->', resolver);

      const selectedIndex = 0
      const hint = resolver.hints[selectedIndex]
      // Ask user which second factor to use.
      // You can get the masked phone number via resolver.hints[selectedIndex].phoneNumber
      // You can get the display name via resolver.hints[selectedIndex].displayName
      if (hint.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        // User selected a phone second factor.
        // const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-id', undefined, auth);

        const phoneInfoOptions = {
          multiFactorHint: hint,
          session: resolver.session,
        }
        const phoneAuthProvider = new PhoneAuthProvider(auth)
        phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
          .then(function (verificationId: any) {
            // verificationId will be needed for sign-in completion.

            let verificationCode = prompt(
              `Enter verification code sent to your phone number Phone Number`,
            )

            if (!verificationCode) {
              return PopupService.pushPopup("Please enter a valid code.")
            }

            const cred = PhoneAuthProvider.credential(verificationId, verificationCode)
            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
            // Complete sign-in. This will also trigger the Auth state listeners.
            resolver.resolveSignIn(multiFactorAssertion).then(function (userCredential: any) {
              // userCredential will also contain the user, additionalUserInfo, optional
              // credential (null for email/password) associated with the first factor sign-in.
              // For example, if the user signed in with Google as a first factor,
              // userCredential.additionalUserInfo will contain data related to Google
              // provider that the user signed in with.
              // - user.credential contains the Google OAuth credential.
              // - user.credential.accessToken contains the Google OAuth access token.
              // - user.credential.idToken contains the Google OAuth ID token.
              // console.log('userCredential---->', userCredential);
            })
          })
          .catch((error: any) => {
            recaptchaVerifier.clear()
          })
      }
      // ...
    },
  })
}

const handleConfirmMfaOtp = async (mfaVerificationId: any, mfaError: any, otp: any, ss: any) => {
  if (!mfaVerificationId) {
    return
  }
  await load(async () => {
    const mfaAssertion = getMultiFactorResolver(auth, mfaError)
    const authCredential = PhoneAuthProvider.credential(mfaVerificationId, otp)
    const userCredential = await signInWithCredential(auth, authCredential)

    const phoneAuthCredential = PhoneAuthProvider.credential(mfaVerificationId, otp)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential)
    await mfaAssertion.resolveSignIn(multiFactorAssertion)
  }, ss)
}

const handleConfirmPhoneOtp = async (confirmationResult: any, phoneOtop: any, ss: any) => {
  if (confirmationResult) {
    await load(async () => {
      await confirmationResult.confirm(phoneOtop)
    }, ss)
  }
}

const handleConfirmOtp = async (confirmationResult: any, otp: any) => {
  if (confirmationResult) {
    LoadingService.setLoading(true)
    try {
      await confirmationResult.confirm(otp)
      // Handle successful login
      PopupService.setPopup(null)
    } catch (error) {
      console.error("Error verifying OTP", error)
    } finally {
      LoadingService.setLoading(false)
    }
  }
}

const handlePhoneSignIn = async (phoneNumber: string, t: any, setConfirmationResult: any) => {
  LoadingService.setLoading(false)
  if (!phoneNumber) {
    PopupService.pushPopup(t("Phone number is required"))
    return
  }
  const win: any = window
  // Initialize RecaptchaVerifier
  if (!win.recaptchaVerifier) {
    win.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible", //"normal", // or 'invisible' based on your needs
      callback: (response: any) => {
        // reCAPTCHA solved - allow the user to send the OTP
        console.log("reCAPTCHA solved", response)
        LoadingService.setLoading(false)
      },
      "expired-callback": () => {
        // Handle expiration
        console.log("reCAPTCHA expired")
        LoadingService.setLoading(false)
      },
    })
  }

  const appVerifier = win.recaptchaVerifier

  // LoadingService.setLoading(true)
  try {
    const result = await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    setConfirmationResult(result)
  } catch (error: any) {
    console.error("Error sending OTP", error)
    PopupService.pushPopup(error.message)
  } finally {
    LoadingService.setLoading(false)
  }
}
