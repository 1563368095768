import { FormControlLabel, Switch } from "@mui/material"
import React, { useState } from "react"

import { useTranslation } from "react-i18next"
import GoogleMapsAutoComplete from "../GoogleMapsAutoComplete"
import { MuiPhone } from "../MuiPhone"

interface Props {
  formData:
    | any
    | {
        phone: string
        address: string
        notifications: boolean
      }
  handleChange: (name: string, value: string | boolean) => void
  onAddressSelect?: any
  existingAddress?: any
  setIsAddressUnsaved?: any
  existingAddressText?: string
  setExistingAddressText?: any
}

const RegistrationStep2: React.FC<Props> = ({
  formData,
  handleChange,
  onAddressSelect,
  existingAddress,
  setIsAddressUnsaved,
  existingAddressText,
  setExistingAddressText,
}: any) => {
  const { t } = useTranslation()
  const [phoneNumber, setPhoneNumber] = useState(formData.phoneNumber || "+1")

  return (
    <div className="flex flex-col w-full gap-5">
      {/* <TextField
        label={`${t("phone")} (${t("Optional")})`}
        variant="outlined"
        fullWidth
        value={formData.phone}
        onChange={(e) => handleChange("phoneNumber", e.target.value)}
        className="mb-4"
      /> */}
      <MuiPhone
        country={formData.userAddress?.country || "us"}
        value={phoneNumber}
        onChange={(phone) => {
          handleChange("phoneNumber", phone)
          //   setPhoneNumberFormatted(formatted)
          setPhoneNumber(phone)
        }}
      />
      <GoogleMapsAutoComplete
        setIsAddressUnsaved={setIsAddressUnsaved}
        onAddressSelect={onAddressSelect}
        existingAddress={existingAddress}
        existingTextAddress={existingAddressText}
        setExistingTextAddress={setExistingAddressText}
      />

      <FormControlLabel
        control={
          <Switch
            checked={formData.pushNotifications}
            onChange={(e) => handleChange("pushNotifications", e.target.checked)}
          />
        }
        label={t("Receive Notifications")}
      />
    </div>
  )
}

export default RegistrationStep2
