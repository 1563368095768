import "react-international-phone/style.css"

import {
  BaseTextFieldProps,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import React from "react"
import {
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
  ParsedCountry,
  usePhoneInput,
} from "react-international-phone"
import { useTranslation } from "react-i18next"

export interface MUIPhoneProps extends BaseTextFieldProps {
  value: string
  onChange: (phone: string, inputValue: string, country: ParsedCountry) => void
  country?: string
}

export const MuiPhone: React.FC<MUIPhoneProps> = ({
  value,
  onChange,
  country: defaultCountry,
  ...restProps
}) => {
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    preferredCountries: ["US", "DO"],
    defaultCountry: defaultCountry || "us",
    value,
    countries: defaultCountries,
    onChange: (data) => {
      onChange(data.phone, data.inputValue, data.country)
    },
  })
  const { t } = useTranslation()

  return (
    <TextField
      variant="outlined"
      label={t("profile.phoneNumber")}
      color="primary"
      placeholder={t("profile.phoneNumber")}
      value={inputValue}
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" style={{ marginRight: "2px", marginLeft: "-8px" }}>
            <Select
              disabled={restProps.disabled}
              MenuProps={{
                style: {
                  height: "300px",
                  width: "360px",
                  top: "10px",
                  left: "-34px",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              sx={{
                width: "max-content",
                // Remove default outline (display only on focus)
                fieldset: {
                  display: "none",
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: "block",
                  },
                },
                // Update default spacing
                ".MuiSelect-select": {
                  padding: "8px",
                  paddingRight: "24px !important",
                },
                svg: {
                  right: 0,
                },
              }}
              value={country.iso2}
              onChange={(e) => setCountry(e.target.value as CountryIso2)}
              renderValue={(value) => <FlagImage iso2={value} style={{ display: "flex" }} />}
            >
              {defaultCountries.map((c) => {
                const country = parseCountry(c)
                return (
                  <MenuItem key={country.iso2} value={country.iso2}>
                    <FlagImage iso2={country.iso2} style={{ marginRight: "8px" }} />
                    <Typography marginRight="8px">{country.name}</Typography>
                    <Typography color="gray">+{country.dialCode}</Typography>
                  </MenuItem>
                )
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  )
}
