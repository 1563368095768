import { Button } from "@mui/material"
import { doc, onSnapshot } from "firebase/firestore"
import { useEffect } from "react"
import { auth, db } from "../firebase"
import { colors, typography } from "../theme"
import { formatCash, formatNumber } from "../utils"

import { useAuthState } from "react-firebase-hooks/auth"
import { PopupService } from "../react-ui-components/components/PopupService"
import { useUserStore } from "./stores/userStore"
import { Image } from "./Tickets/Image"
import { Login } from "./LoginScreen/Login"
import { useTranslation } from "react-i18next"
import ExchangePointsScreen from "./ExchangePointsScreen"

export interface UserPointsProps {
  style?: any
  textStyle?: any
  isNoLink?: boolean
  textPosition?: "LEFT" | "RIGHT"
  size?: number
  iconColor?: string
  iconStyle?: any
  showMinDigits?: number
  precision?: number
  complete?: boolean
  variant?: "text" | "outlined" | "contained"
  className?: string
}

export const UserPoints = function UserPoints(props: UserPointsProps) {
  const {
    style,
    isNoLink,
    size,
    textPosition,
    textStyle,
    iconColor,
    iconStyle,
    showMinDigits,
    precision,
    complete,
    variant = "contained",
    className,
  } = props

  const $styles = { ...$container, ...style }
  const $iconStyles = { ...$icon, ...iconStyle }
  const { points, isLoggedIn, setPoints } = useUserStore()
  const [user] = useAuthState(auth)
  const { t } = useTranslation()

  useEffect(() => {
    if (!isLoggedIn || !user) {
      return
    }
    const id: any = user.uid

    const querySnapshot = doc(db, "users", id)
    return onSnapshot(querySnapshot, {
      next: (snap) => {
        setPoints(snap?.data()?.points || 0)
      },
      error: (data) => {
        console.log("USERS-->", data)
      },
    })
  }, [isLoggedIn, user])

  if (!isLoggedIn) {
    return null
  }

  return (
    <Button
      variant={variant}
      style={$styles}
      className={`flex flex-row gap-1 p-1 ${className} w-full`}
      startIcon={
        <div className="flex flex-row gap-1 items-center">
          <div className="flex flex-row items-center">
            {points ? (
              <span className="text-[8px] sm:text-xs ml-1">
                {complete
                  ? formatNumber(points)
                  : formatCash(Math.floor(points), showMinDigits, precision)}
              </span>
            ) : null}
            <Image
              src={require("../assets/icons/FacilCoin2.png")}
              width={size || 20}
              style={$iconStyles}
              color={iconColor}
              alt="winfacil coin"
            />
          </div>

          <span className="text-[8px] sm:text-xs">{t("points")}</span>
        </div>
      }
      // style={{ backgroundColor: colors.transparent }}
      onClick={() => {
        PopupService.setPopup(<ExchangePointsScreen />)
      }}
    ></Button>
  )
}

const $container: any = {
  justifyContent: "center",
  flexDirection: "row",
}

const $text: any = {
  fontFamily: typography.primary.normal,
  fontSize: 14,
  color: colors.palette.primary500,
  marginTop: 5,
}

const $icon: any = {
  marginLeft: 1,
}
