import { Alert, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth } from "./firebase";
import { sendEmailVerification } from "firebase/auth";

import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useUserStore } from "./components/stores/userStore";
import { PopupService } from "./react-ui-components/components/PopupService";


export const WarningMessage: React.FC = () => {

    const { t } = useTranslation();
  
    const { displayContentConfig } = useUserStore();
  
    const [cooldownDuration, setCooldownDuration] = useState<number>(
      displayContentConfig?.resendEmailCooldownMinutes || 10
    ); // Default to 10 minutes
    const [isCooldownActive, setIsCooldownActive] = useState<boolean>(false);
    const [cooldownEndTime, setCooldownEndTime] = useState<number | null>(null);
    const [remainingTime, setRemainingTime] = useState<string>("" + cooldownDuration); // Remaining time in MM:SS format
  
    useEffect(() => {
      setCooldownDuration(displayContentConfig?.resendEmailCooldownMinutes || 10);
    }, [displayContentConfig?.resendEmailCooldownMinutes]);
  
    useEffect(() => {
      // Load cooldown end time from local storage
      const savedCooldownEndTime = localStorage.getItem(getStorageName());
      if (savedCooldownEndTime) {
        const endTime = parseInt(savedCooldownEndTime, 10);
        if (endTime > Date.now()) {
          setIsCooldownActive(true);
          setCooldownEndTime(endTime);
          startCountdown(endTime);
        }
      }
    }, []);
  
    const startCountdown = (endTime: number) => {
      // Update remaining time every second
      const interval = setInterval(() => {
        const timeLeft = endTime - Date.now();
        if (timeLeft > 0) {
          setRemainingTime(formatTime(timeLeft));
        } else {
          clearInterval(interval);
          setIsCooldownActive(false);
          setRemainingTime("");
          localStorage.removeItem(getStorageName());
        }
      }, 1000);
    };
  
    const formatTime = (milliseconds: number): string => {
      const totalSeconds = Math.floor(milliseconds / 1000);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    };
  
    const user = auth.currentUser;
    const getStorageName = () => {
      return `${user?.email}-resendEmailCooldownEndTime`;
    };

    const handleResendVerification = async () => {
      try {
        if (user) {
          await sendEmailVerification(user);
  
          const endTime = Date.now() + cooldownDuration * 60 * 1000; // Cooldown in milliseconds
          setIsCooldownActive(true);
          setCooldownEndTime(endTime);
          localStorage.setItem(getStorageName(), endTime.toString());
  
          startCountdown(endTime);
  
          // // Clear cooldown after the duration
          // setTimeout(() => {
          //   setIsCooldownActive(false)
          //   localStorage.removeItem(getStorageName())
          // }, cooldownDuration * 60 * 1000)
          PopupService.pushPopup(t("emailVerificationSent").replace("$1", user.email || ""));
        }
      } catch (error: any) {
        PopupService.pushPopup(error.message);
      }
    };

    async function logWarning(message: string) {
      try {
        await fetch('/api/log-warning', {
          method: 'POST',
          body: JSON.stringify({ message }),
        });
      } catch (error) {
        console.error('Error logging warning:', error);
      }
    }

    return (
        <Box className="flex flex-col justify-center mb-4">
          <Alert severity="warning" className="flex mt-4">
            <Typography>
              {t("Your email is not verified ...").replace("$1", auth.currentUser?.email || "")}
            </Typography>
            <div className="flex flex-col justify-center">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleResendVerification}
                className="place-self-center mt-2"
                disabled={isCooldownActive}
              >
                {/* {t("Resend Verification Email")} */}
                {isCooldownActive
                  ? `${t("Wait")} ${remainingTime} ${t("min")}`
                  : t("Resend Verification Email")}
              </Button>
            </div>
          </Alert>
        </Box>
      );
  
    if (!user) {
      return null;
    }
  
    const isEmailVerified = false;// isEmailProvider ? user.emailVerified : true;
  
    const showResendVerification = true;//!isEmailVerified && isEmailProvider;
  
    if (!isEmailVerified && showResendVerification) {
      return (
        <Box className="flex flex-col justify-center mb-4">
          <Alert severity="warning" className="flex mt-4">
            <Typography>
              {t("Your email is not verified ...").replace("$1", auth.currentUser?.email || "")}
            </Typography>
            <div className="flex flex-col justify-center">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleResendVerification}
                className="place-self-center mt-2"
                disabled={isCooldownActive}
              >
                {/* {t("Resend Verification Email")} */}
                {isCooldownActive
                  ? `${t("Wait")} ${remainingTime} ${t("min")}`
                  : t("Resend Verification Email")}
              </Button>
            </div>
          </Alert>
        </Box>
      );
    }
    return null;
  }
